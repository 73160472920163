<template lang="pug">
div
  TableV2(
    :headers="headers"
    :items="items.results || []"
    :pageCount="items.count"
    :currentPage="items.current"
    :querySearch="getListNewAccounts"
    :actions="actions"
    :isLoading="isLoading")
</template>

<script>
import StatementsSearch from '@/components/molecules/ReportSearch/ReportSearch.vue'
import Paginate from '@/components/atoms/Paginate.vue'
import { checkAccess } from '@/mixins/permissions'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    StatementsSearch,
    Paginate
  },
  data () {
    return {
      headers: [
        { value: 'created_at', text: this.$i18n.t('createDate'), class: 'd-flex justify-start align-center' },
        { value: 'fullName', text: this.$i18n.t('fullName'), sortable: false },
        { value: 'birthday', text: this.$i18n.t('dateBorn'), sortable: false },
        { value: 'phone', text: this.$i18n.t('phoneNumber'), sortable: false },
        { value: 'passport', text: this.$i18n.t('passport'), sortable: false },
        { value: 'inn', text: this.$i18n.t('taxNumber'), sortable: false },
        { value: 'status_document', text: this.$i18n.t('status'), class: 'd-flex justify-start align-center' },
        { value: 'event', text: this.$i18n.t('actions'), class: 'mw-0', sortable: false }
      ],
      actions: [
        { id: 1, tooltip: 'tooltip.go', to: ({ id }) => ({ name: 'new-accounts-info', params: { documentID: id } }), color: 'blue', name: 'mdi-arrow-right' }
      ]
    }
  },
  beforeCreate () {
    this.$store.commit('setActivePage', { name: 'newAccounts', access: checkAccess('menuItem-verificationAccount') })
  },
  computed: {
    ...mapState({
      items: state => state.sailor.newAccounts,
      isLoading: state => state.sailor.isLoading
    })
  },

  methods: {
    ...mapActions(['getListNewAccounts'])
  }
}
</script>
